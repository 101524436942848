import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import esES from 'antd/es/locale/es_ES';  // Importa el locale español de Ant Design
import dayjs from 'dayjs';  // Importa Day.js
import 'dayjs/locale/es';  // Importa el locale en español para Day.js
import localizedFormat from 'dayjs/plugin/localizedFormat';  // Importa plugin para formatos localizados

dayjs.extend(localizedFormat);  // Extiende Day.js con los formatos localizados
dayjs.locale('es');  // Establece Day.js para que use el locale en español

console.log(dayjs.locale());  // Verifica que el locale esté configurado correctamente

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConfigProvider locale={esES}>  {/* Aplica el locale español de Ant Design */}
      <App />
    </ConfigProvider>
  </React.StrictMode>
);

reportWebVitals();
