import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import CompCreateProv from './createProv';
import { FaTrash, FaPlus, FaTimes, FaSave, FaEdit } from 'react-icons/fa'; // Importamos íconos de react-icons
import { format } from 'date-fns';
import { useAuth } from '../context/AuthContext';  
import ModalProveedores from './proveedores/ModalProveedores';
const CompEditProduct = React.forwardRef(({ productId, onSave, handleCloseEdit }, ref) => {
    const { token } = useAuth();  // Obtener el token del contexto
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [proveedores, setProveedores] = useState([]);


    const [product, setProduct] = useState({
        nombre_producto: '',
        stock_unidades: '',
        es_refrigerado: false,
        fecha_vencimiento: '',
        cantidad_pallets: '',
        cantidad_bultos: '',
        proveedores: [], // Array para múltiples proveedores
        codigo_barras: [{ codigo: '' }], // Array de objetos para manejar múltiples Plug asociado
        precios: {
            precio_compra: 0,
            precio: 0,
            precio_mayorista_a: 0,
            precio_mayorista_b: 0,
            precio_mayorista_c: 0,
        },
    });

    const [open, setOpen] = useState(false);

    // Filtra solo los proveedores seleccionados
    const proveedoresSeleccionados = proveedores.filter((prov) =>
        product.proveedores.includes(prov.id)
    );


    useEffect(() => {


        const fetchData = async () => {
            try {
                if (productId) {
                    const productResponse = await axios.get(
                        `${process.env.REACT_APP_API_URL}/productos/admin/${productId}`,
                        {
                            headers: {
                                'x-auth-token': token,  // Agregar el token en los headers
                            },
                        }
                    );
                    const producto = productResponse.data.producto;
                    const precioActivo = producto.precios[0] || {
                        precio_compra: 0,
                        precio: 0,
                        precio_mayorista_a: 0,
                        precio_mayorista_b: 0,
                        precio_mayorista_c: 0,
                    };
                    const proveedorIds = producto.proveedores ? producto.proveedores.map((p) => parseInt(p.id, 10)) : [];
                    setProduct({
                        ...producto,
                        proveedores: proveedorIds,
                        codigo_barras: producto.codigo_barras || [''],
                        precios: {
                            precio_compra: parseFloat(precioActivo.precio_compra) || 0,
                            precio: parseFloat(precioActivo.precio) || 0,
                            precio_mayorista_a: parseFloat(precioActivo.precio_mayorista_a) || 0,
                            precio_mayorista_b: parseFloat(precioActivo.precio_mayorista_b) || 0,
                            precio_mayorista_c: parseFloat(precioActivo.precio_mayorista_c) || 0,
                        },
                    });
                }

                const proveedoresResponse = await axios.get(
                    `${process.env.REACT_APP_API_URL}/proveedores`,
                    {
                        headers: {
                            'x-auth-token': token,  // Agregar el token en los headers
                        },
                    }
                );
                setHasUnsavedChanges(false);
                setProveedores(proveedoresResponse.data.proveedores);
            } catch (error) {
                console.error('Error fetching product data:', error);
            }
        };

        fetchData();
    }, [productId]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setHasUnsavedChanges(true)
        if (type === 'checkbox') {
            // Para el checkbox de es_refrigerado
            if (name === 'es_refrigerado') {
                setProduct((prevProduct) => ({
                    ...prevProduct,
                    es_refrigerado: checked,
                }));
            } else if (name === 'proveedores') {
                // Para el checkbox de proveedores
                const proveedorId = parseInt(value, 10);
                setProduct((prevProduct) => ({
                    ...prevProduct,
                    proveedores: checked
                        ? [...prevProduct.proveedores, proveedorId]
                        : prevProduct.proveedores.filter((id) => id !== proveedorId),
                }));
            }
        } else {
            // Para otros tipos de input
            setProduct((prevProduct) => ({
                ...prevProduct,
                [name]: type === 'number' ? (value ? parseFloat(value) : '') : value,
            }));
        }
    };

    const handleAddCodigoBarras = () => {
        setHasUnsavedChanges(true)
        setProduct((prevProduct) => ({
            ...prevProduct,
            codigo_barras: [...prevProduct.codigo_barras, { codigo: '' }],
        }));
    };

    const handleRemoveCodigoBarras = (index) => {
        setHasUnsavedChanges(true)
        setProduct((prevProduct) => ({
            ...prevProduct,
            codigo_barras: prevProduct.codigo_barras.filter((_, i) => i !== index),
        }));
    };

    const handleChangeCodigoBarras = (index, value) => {
        setHasUnsavedChanges(true)
        const updatedCodigos = [...product.codigo_barras];
        updatedCodigos[index].codigo = value;
        setProduct({
            ...product,
            codigo_barras: updatedCodigos,
        });
    };

    const handlePriceChange = (e) => {
        const { name, value } = e.target;
        setHasUnsavedChanges(true)

        setProduct((prevProduct) => ({
            ...prevProduct,
            precios: {
                ...prevProduct.precios,
                [name]: value ? parseFloat(value) : 0,
            },
        }));
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const apiUrl = productId
                ? `${process.env.REACT_APP_API_URL}/productos/admin/${productId}`
                : `${process.env.REACT_APP_API_URL}/productos/admin`;
            const method = productId ? 'put' : 'post';

            await axios[method](apiUrl, product, {
                headers: {
                    'x-auth-token': token, // Agregar el token en los headers
                },
            });
            alert('Producto actualizado correctamente')

            console.log("esto no deber+oa mostrar");
            
            onSave(); // Callback para indicar que el producto fue guardado
            handleCloseEdit()
            scrollToTop()
            setHasUnsavedChanges(false);
        } catch (error) {
            console.error('Error saving product:', error);
        }
    };


    const handleOpenCreateProv = () => setOpen(true);
    const onClose = () => setOpen(false);

    const onCreateProveedor = async () => {
        try {
            const proveedoresResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}/proveedores`,
                {
                    headers: {
                        'x-auth-token': token, // Incluye el token en los encabezados
                    },
                }
            );
            setProveedores(proveedoresResponse.data.proveedores);
        } catch (error) {
            console.error('Error al obtener proveedores:', error);
        }
    };


    const formattedUpdatedAt = product.updatedAt
        ? format(new Date(product.updatedAt), 'dd/MM/yyyy HH:mm')
        : 'N/A';


    // Actualiza ref para indicar cambios no guardados
    useEffect(() => {
        if (ref) {
            ref.current = { hasUnsavedChanges };
        }
    }, [hasUnsavedChanges]);

    return (

        <form onSubmit={handleSubmit} className="space-y-6 p-6 bg-white rounded-lg shadow-md max-w-4xl mx-auto ">
            <h2 className="text-2xl font-bold text-center mb-6">
                {productId ? 'Actualizar Producto' : 'Agregar Producto'}
            </h2>
            <i className='text-sm text-gray-500'>El stock podrá ser modificado únicamente durante esta primera etapa, en la cual buscamos alinear el stock físico con el registrado en el sistema.</i>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Nombre del Producto */}

                <div>
                    <label htmlFor="stock_unidades" className=" font-medium flex flex-start text-gray-700">Cantidad:</label>
                    <input
                        type="number"
                        id="stock_unidades"
                        name="stock_unidades"
                        value={product.stock_unidades || ''}
                        onChange={handleChange}
                        min="0"
                        className="w-full p-2 border flex flex-start  border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                </div>
                <div>
                    <label htmlFor="nombre_producto" className="block font-medium text-gray-700">Nombre del Producto:</label>
                    <input
                        type="text"
                        id="nombre_producto"
                        name="nombre_producto"
                        value={product.nombre_producto}
                        onChange={handleChange}
                        required
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                </div>
                <div>
                    <label htmlFor="cantidad_bultos" className=" font-medium flex flex-start text-gray-700">Cantidad por bulto:</label>
                    <input
                        type="number"
                        id="cantidad_bultos"
                        name="cantidad_bultos"
                        value={product.cantidad_bultos || ''}
                        onChange={handleChange}
                        min="0"
                        className="w-full p-2 border flex flex-start  border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                </div>
                <div>
                    <label htmlFor="cantidad_pallets" className=" font-medium flex flex-start text-gray-700">Bultos por pallet:</label>
                    <input
                        type="number"
                        id="cantidad_pallets"
                        name="cantidad_pallets"
                        value={product.cantidad_pallets || ''}
                        onChange={handleChange}
                        min="0"
                        className=" w-full p-2 border flex flex-start  border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                </div>

                {/* Refrigerado */}
                <div className="md:col-span-2 flex items-center border p-3 rounded-md bg-gray-100">
                    <input
                        type="checkbox"
                        id="es_refrigerado"
                        name="es_refrigerado"
                        checked={product.es_refrigerado}
                        onChange={handleChange}
                        className="h-5 w-5 text-indigo-500"
                    />
                    <label htmlFor="es_refrigerado" className="ml-2 font-medium text-gray-700">
                        ¿Es refrigerado?
                    </label>
                </div>


                {/* Fecha de Vencimiento */}
                <div>
                    <label htmlFor="fecha_vencimiento" className="block font-medium text-gray-700">Fecha de Vencimiento:</label>
                    <input
                        type="date"
                        id="fecha_vencimiento"
                        name="fecha_vencimiento"
                        value={product.fecha_vencimiento || ''}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                </div>

                {/*Plug asociado */}
                <div>
                    <label className=" font-medium text-gray-700">Plug asociado:</label>
                    {product.codigo_barras.map((codigoObj, index) => (
                        <div key={index} className="flex items-center space-x-4 mb-2">
                            <input
                                type="text"
                                value={codigoObj.codigo}
                                onChange={(e) => handleChangeCodigoBarras(index, e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-md"
                            />
                            <button
                                type="button"
                                onClick={() => handleRemoveCodigoBarras(index)}
                                className="text-red-500 hover:text-red-700"
                            >
                                <FaTrash />
                            </button>
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={handleAddCodigoBarras}
                        className="flex items-center text-indigo-600 hover:text-indigo-800 mt-2"
                    >
                        <FaPlus className="mr-1" />
                        Agregar plug asociado
                    </button>
                    {open && (
                        <CompCreateProv onClose={onClose} onCreateProveedor={onCreateProveedor} />
                    )}
                </div>

                {/* Proveedores */}
                <div className="col-span-2">
                    <label className="block font-medium text-gray-700">Proveedores:</label>

                    <div className="mt-2">
                        {proveedoresSeleccionados.length > 0 ? (
                            proveedoresSeleccionados.map((prov) => (
                                <div key={prov.id} className="flex justify-between items-center bg-gray-100 px-4 py-2 rounded-lg shadow mb-2">
                                    <span>{prov.nombre}</span>
                                </div>
                            ))
                        ) : (
                            <p className="text-gray-500">No hay proveedores seleccionados</p>
                        )}
                    </div>

                    {/* Botones Modal */}
                    <div className='flex justify-around'>
                        <button
                            type="button"
                            onClick={() => setModalOpen(true)}
                            className="flex items-center text-indigo-600 hover:text-indigo-800 mt-2"
                        >
                            <FaEdit className="mr-1" />
                            Gestionar Proveedores
                        </button>
                        <button
                            type="button"
                            onClick={handleOpenCreateProv}
                            className="flex items-center text-indigo-600 hover:text-indigo-800 mt-2"
                        >
                            <FaPlus className="mr-1" />
                            Crear Proveedor
                        </button>
                    </div>
                    {/* Modal */}
                    {modalOpen && (
                        <ModalProveedores
                            proveedores={proveedores}
                            product={product}
                            handleChange={handleChange}
                            onClose={() => setModalOpen(false)}
                        />
                    )}
                </div>
                {/* Precios */}
                <div className="col-span-2 space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {['precio_compra', 'precio', 'precio_mayorista_a', 'precio_mayorista_b', 'precio_mayorista_c'].map((field) => (
                            <div key={field}>
                                <label htmlFor={field} className="block font-medium text-gray-700">
                                    {field === 'precio_compra' && 'Precio sin IVA'}
                                    {field === 'precio' && 'Precio de Venta'}
                                    {field === 'precio_mayorista_a' && 'Precio Mayorista A'}
                                    {field === 'precio_mayorista_b' && 'Precio Mayorista B'}
                                    {field === 'precio_mayorista_c' && 'Precio Mayorista C'}
                                </label>
                                <input
                                    type="number"
                                    id={field}
                                    name={field}
                                    value={product.precios[field] || null}
                                    onChange={handlePriceChange}
                                    min="0"
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="col-span-2">
                <label htmlFor="updatedAt" className="block font-medium text-gray-700">Última Actualización:</label>
                <input
                    type="text"
                    id="updatedAt"
                    disabled
                    value={formattedUpdatedAt}
                    className="w-full p-2 border border-gray-300 rounded-md focus:outline-none bg-gray-100"
                />
            </div>

            {/* Botones de acción */}
            <div className="flex justify-end space-x-4 mt-6">
                <button
                    type="button"
                    onClick={() => handleCloseEdit()}
                    className="flex items-center bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-6 rounded-md"
                >
                    <FaTimes className="mr-2" />
                    Cancelar
                </button>
                <button
                    type="submit"
                    className="flex items-center bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-6 rounded-md"
                >
                    <FaSave className="mr-2" />
                    {'Actualizar Producto'}
                </button>
            </div>
        </form>
    );
});

export default CompEditProduct;
