import { useState, useEffect } from "react";
import { FaTimes, FaSearch, FaEdit, FaTrash } from "react-icons/fa";
import EditProv from "./EditProv";
import { useAuth } from '../../context/AuthContext';
import axios from "axios";


const ModalProveedores = ({ proveedores, product, handleChange, onClose }) => {
    const { token } = useAuth();  // Obtener el token del contexto

    const [search, setSearch] = useState("");
    const [proveedorSeleccionado, setProveedorSeleccionado] = useState(null);
    const [proveedoresList, setProveedoresList] = useState(proveedores);

    // Función para recargar proveedores desde la API
    const fetchProveedores = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/proveedores`,
                {
                    headers: {
                        'x-auth-token': token, // Incluye el token en los encabezados
                    },
                }
            );
            console.log(response);


            setProveedoresList(response.data.proveedores);
        } catch (error) {
            console.error("Error al obtener proveedores:", error);
        }
    };

    useEffect(() => {
        setProveedoresList(proveedores);
    }, [proveedores]);

    const handleDeleteProveedor = async (id) => {
        if (window.confirm("¿Seguro que deseas eliminar este proveedor?")) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/proveedores/${id}`, {
                    headers: { "x-auth-token": token }, // Enviar token en la eliminación
                });
                alert("Proveedor eliminado exitosamente");
                fetchProveedores(); // 🔄 Recargar proveedores después de eliminar
            } catch (error) {
                alert("Error al eliminar proveedor");
                console.error("Error al eliminar proveedor:", error);
            }
        }
    };
    const filteredProveedores = proveedoresList.filter((prov) =>
        prov.nombre.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-lg w-96 max-h-[80vh] overflow-y-auto">
                {/* Encabezado */}
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-semibold">Seleccionar Proveedores</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-800">
                        <FaTimes size={18} />
                    </button>
                </div>

                {/* Barra de búsqueda */}
                <div className="relative mb-4">
                    <input
                        type="text"
                        placeholder="Buscar proveedor..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className="w-full border rounded-lg px-4 py-2 pr-10 focus:outline-none focus:ring-2 focus:ring-indigo-300"
                    />
                    <FaSearch className="absolute right-3 top-3 text-gray-400" />
                </div>

                {/* Lista de proveedores */}
                <div>
                    {filteredProveedores.length > 0 ? (
                        filteredProveedores.map((prov) => (
                            <div key={prov.id} className="flex items-center justify-between bg-gray-100 px-4 py-2 rounded-lg shadow mb-2">
                                <div>
                                    <p className="font-medium">{prov.nombre}</p>
                                    <p className="text-sm text-gray-500">
                                        {prov.abreviatura} - {prov.codigo}
                                    </p>
                                </div>
                                <div className="flex gap-2">
                                    {/* Seleccionar */}
                                    <input
                                        type="checkbox"
                                        checked={product.proveedores.includes(prov.id)}
                                        onChange={handleChange}
                                        value={prov.id}
                                        name="proveedores"
                                        className="h-5 w-5 text-indigo-500"
                                    />

                                    {/* Editar */}
                                    <button
                                        onClick={() => setProveedorSeleccionado(prov)}
                                        className="text-blue-600 hover:text-blue-800"
                                    >
                                        <FaEdit />
                                    </button>

                                    {/* Eliminar */}
                                    <button type="button" onClick={() => handleDeleteProveedor(prov.id)} className="text-red-600 hover:text-red-800">
                                        <FaTrash />
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-gray-500 text-center">No se encontraron proveedores</p>
                    )}
                </div>
            </div>

            {proveedorSeleccionado && (
                <EditProv
                    proveedor={proveedorSeleccionado}
                    onCancel={() => setProveedorSeleccionado(null)}
                    onUpdate={fetchProveedores} // 🔄 Pasar función para actualizar proveedores
                />
            )}
        </div>
    );
};

export default ModalProveedores;
