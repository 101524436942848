import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { useParams, Link } from "react-router-dom";
import { FaShoppingCart, FaStore, FaHistory } from "react-icons/fa";

const CompCarrito = () => {
  const [cart, setCart] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedWholesalePrice, setSelectedWholesalePrice] = useState('precio_mayorista_a');
  const { token } = useAuth();
  const { tipo } = useParams();
  const [discount, setDiscount] = useState(0); // Estado para el descuento

  const [paymentMethod, setPaymentMethod] = useState('efectivo'); // Predeterminado: efectivo
  const caja = localStorage.getItem('cajaAbiertaId');

  // Cargar productos una sola vez al montar el componente
  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/productos/admin`, {
          headers: { "x-auth-token": token },
        });
        setProductsData(response.data.productos);
      } catch (error) {
        console.error("Error al cargar productos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [token]);

  const filteredProducts = useMemo(() => {
    if (!searchTerm) return productsData; // Si no hay búsqueda, mostrar todos

    const term = searchTerm.toLowerCase().trim();

    return productsData.filter((product) => {
      const nameMatch = product.nombre_producto.toLowerCase().includes(term);

      // Busca dentro de `codigo_barras[]`, accediendo a `codigo`
      const barcodeMatch = product.codigo_barras?.some((item) =>
        item.codigo.toLowerCase().includes(term)
      );

      return nameMatch || barcodeMatch;
    });
  }, [searchTerm, productsData]);



  // Función para manejar la búsqueda por Plug asociado (cuando se presiona Enter)
  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter" && filteredProducts.length === 1) {
      addToCart(filteredProducts[0]);
      setSearchTerm("");
    }
  };


  const addToCart = (product) => {
    if (!caja) {
      alert('No puedes realizar ventas sin abrir una caja');
      return;
    }

    setCart((prevCart) => {
      const existingProduct = prevCart.find((p) => p.id === product.id);

      if (existingProduct) {
        if (existingProduct.quantity < product.stock_unidades) {
          setSearchTerm(""); // Restablecer búsqueda después de agregar

          return prevCart.map((p) =>
            p.id === product.id ? { ...p, quantity: p.quantity + 1 } : p
          );
        } else {
          alert('No puedes agregar más unidades de las disponibles en stock.');
        }
      } else {
        if (product.stock_unidades > 0) {
          setSearchTerm(""); // Restablecer búsqueda después de agregar
          return [...prevCart, { ...product, quantity: 1 }];
        } else {
          alert('Este producto está fuera de stock.');
        }
      }
      return prevCart;
    });
  };
  const getPrice = (product) => {
    // Si la venta es mayorista, mantiene la lógica actual
    if (tipo === "mayorista") {
      return (
        product?.precios?.[0]?.[selectedWholesalePrice] ||
        product?.precios?.[0]?.precio ||
        "N/A"
      );
    }
    const quantity = product.quantity || 0;
    const unidadesPorBulto = product.cantidad_bultos || 0; // Si es 0, no hay bultos
    const unidadesPorPalet = product.cantidad_pallets || 0; // Si es 0, no hay pallets

    // Si no hay bultos ni pallets, usa el precio general
    if (unidadesPorBulto === 0 && unidadesPorPalet === 0) {
      return product?.precios?.[0]?.precio || "N/A";
    }

    const cantidadUnidadesBulto = unidadesPorBulto; // Un bulto en unidades
    const cantidadUnidadesMayoristaB = cantidadUnidadesBulto * 10; // 10 bultos en unidades
    const cantidadUnidadesPalet = unidadesPorPalet > 0 ? unidadesPorBulto * unidadesPorPalet : null; // Solo si hay pallets

    // Si hay cantidad_pallets y la cantidad en unidades es igual o mayor a un palet, usar precio mayorista C
    if (cantidadUnidadesPalet !== null && quantity >= cantidadUnidadesPalet) {
      return product?.precios?.[0]?.precio_mayorista_c || product?.precios?.[0]?.precio || "N/A";
    }

    // Si la cantidad en unidades es igual o mayor a 10 bultos pero menor que un palet (si existe), usar precio mayorista B
    if (quantity >= cantidadUnidadesMayoristaB && (cantidadUnidadesPalet === null || quantity < cantidadUnidadesPalet)) {
      return product?.precios?.[0]?.precio_mayorista_b || product?.precios?.[0]?.precio || "N/A";
    }

    // Si la cantidad en unidades es igual o mayor a un bulto pero menor a 10 bultos, usar precio mayorista A
    if (quantity >= cantidadUnidadesBulto && quantity < cantidadUnidadesMayoristaB) {
      return product?.precios?.[0]?.precio_mayorista_a || product?.precios?.[0]?.precio || "N/A";
    }

    // Si la cantidad es menor a un bulto o no hay configuración de bultos, usar el precio general
    return product?.precios?.[0]?.precio || "N/A";
  };

  const isDiscounted = (product) => product.tieneDescuento;
  const getDiscountedPrice = (product) => {
    if (isDiscounted(product)) {
      return parseFloat(product.precioFinal.toFixed(2));
    }
    return getPrice(product);
  };

  const handleWholesalePriceChange = (e) => {
    setSelectedWholesalePrice(e.target.value);
  };



  const removeFromCart = (productId) => {
    setCart((prevCart) => prevCart.filter((product) => product.id !== productId));
  };


  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method); // Solo permite seleccionar uno
  };


  const increaseQuantity = (productId) => {
    setCart((prevCart) =>
      prevCart.map((product) => {
        if (product.id === productId) {
          if (product.quantity < product.stock_unidades) {
            return { ...product, quantity: product.quantity + 1 };
          } else {
            alert('No puedes agregar más unidades de las disponibles en stock.');
          }
        }
        return product;
      })
    );
  };


  const decreaseQuantity = (productId) => {
    setCart((prevCart) =>
      prevCart.map((product) =>
        product.id === productId && product.quantity > 1
          ? { ...product, quantity: product.quantity - 1 }
          : product
      )
    );
  };

  const calculateTotal = () => {
    const total = cart.reduce((acc, product) => acc + getDiscountedPrice(product) * product.quantity, 0);
    return parseFloat(total.toFixed(2));
  };
  const handleCheckout = async () => {
    const invalidProduct = cart.find((item) => !item.quantity || item.quantity <= 0);

    if (invalidProduct) {
      alert(`El producto "${invalidProduct.nombre_producto}" tiene una cantidad inválida. Corrígelo antes de continuar.`);
      return;
    }

    // Verificar si el descuento es un número válido (puede estar vacío pero no negativo ni mayor a 100)
    const descuentoAplicado = discount > 0 ? discount : 0;

    if (descuentoAplicado > 100) {
      alert("El descuento no puede ser mayor al 100%.");
      return;
    }

    // Aplicar el descuento como porcentaje
    const totalSinDescuento = calculateTotal();
    const totalConDescuento = totalSinDescuento * (1 - descuentoAplicado / 100);

    setLoading(true);

    try {
      const payload = {
        productos: cart.map((item) => ({
          id: item.id,
          cantidad: item.quantity,
          precio: getDiscountedPrice(item),
          stock_unidades: item.stock_unidades,
        })),
        total: totalConDescuento, // Enviar el total con el descuento aplicado
        mayorista: tipo === 'mayorista' ? selectedWholesalePrice : false, 
        detalles_pago: paymentMethod,
        descuento: descuentoAplicado, // Enviar el porcentaje de descuento aplicado
      };

      // Realizar la solicitud POST usando axios
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/ventas`,
        payload,
        {
          headers: {
            'x-auth-token': token, 
          }
        }
      );

      console.log(response);

      if (response.status !== 201) {
        throw new Error('Error al realizar la venta');
      }

      setCart([]);
      setDiscount('');
      alert('Venta realizada con éxito');
    } catch (error) {
      alert(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const handleQuantityChange = (productId, value) => {
    if (value === '') {
      setCart((prevCart) =>
        prevCart.map((product) =>
          product.id === productId ? { ...product, quantity: '' } : product
        )
      );
      return;
    }

    const newQuantity = parseInt(value, 10);

    if (!isNaN(newQuantity) && newQuantity >= 1) {
      setCart((prevCart) =>
        prevCart.map((product) =>
          product.id === productId
            ? { ...product, quantity: Math.min(newQuantity, product.stock_unidades) }
            : product
        )
      );
    }
  };


  const handleDiscountChange = (event) => {
    let value = event.target.value.trim(); // Eliminar espacios en blanco

    if (value === "") {
      setDiscount(""); // Permitir vacío
    } else {
      let parsedValue = parseFloat(value);
      if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 100) {
        setDiscount(parsedValue);
      }
    }
  };

  // Función para calcular el total con descuento
  const calculateTotalWithDiscount = () => {
    const total = calculateTotal(); // Obtiene el total normal
    const discountAmount = (total * discount) / 100; // Calcula el descuento
    return parseFloat(total - discountAmount.toFixed(2)); // Retorna el total con el descuento aplicado
  };


  return (

    <div className="container mx-auto p-4 max-w-7xl">
      <h1 className='text-primary text-center py-4 mb-4'>Relizar Venta</h1>
      {loading && <p>Cargando...</p>}

      {tipo === 'mayorista' && (
        <div className=" mb-6 flex flex-col items-end">
          <label className="block text-gray-700 font-bold mb-2">Selecciona el precio mayorista:</label>
          <div className="flex space-x-4">
            <label>
              <input
                type="radio"
                value="precio_mayorista_a"
                checked={selectedWholesalePrice === 'precio_mayorista_a'}
                onChange={handleWholesalePriceChange}
              />
              <span className="ml-2">Mayorista A</span>
            </label>
            <label>
              <input
                type="radio"
                value="precio_mayorista_b"
                checked={selectedWholesalePrice === 'precio_mayorista_b'}
                onChange={handleWholesalePriceChange}
              />
              <span className="ml-2">Mayorista B</span>
            </label>
            <label>
              <input
                type="radio"
                value="precio_mayorista_c"
                checked={selectedWholesalePrice === 'precio_mayorista_c'}
                onChange={handleWholesalePriceChange}
              />
              <span className="ml-2">Mayorista C</span>
            </label>
          </div>
        </div>
      )}



      {/* Buscador de productos */}
      <div className="mb-6 flex items-center w-full relative">
        {/* Buscador centrado horizontalmente */}
        <div className="absolute left-1/2 transform -translate-x-1/2">
          <input
            type="text"
            autoFocus
            placeholder="Buscar producto..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleSearchKeyDown}
            className="w-full md:w-[400px] p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
          />
        </div>

        {/* Botón en el lado derecho */}
        <div className="ml-auto flex space-x-6">
          <Link
            to={tipo === "mayorista" ? "/venta/minorista" : "/venta/mayorista"}
            className="flex items-center gap-3 bg-gradient-to-r from-blue-500 to-indigo-500 text-white py-3 px-6 rounded-lg shadow-lg hover:from-indigo-600 hover:to-blue-600 transition-all transform hover:scale-105"
          >
            {tipo === "mayorista" ? (
              <>
                <FaStore className="text-lg" />
                <span className="ml-2">Venta por Menor</span>
              </>
            ) : (
              <>
                <FaShoppingCart className="text-lg" />
                <span className="ml-2">Venta Mayorista</span>
              </>
            )}
          </Link>

          <Link
            to="/ventas" // Añade la ruta a historial, si es necesaria
            className="flex items-center gap-3 text-gray-700 hover:text-indigo-500 transition-colors duration-200"
          >
            <FaHistory className="text-lg" />
            <span className="ml-2">Historial</span>
          </Link>
        </div>

      </div>



      {/* Resultados de la búsqueda */}
      <div
        className="bg-white shadow-md rounded-lg max-h-64 overflow-y-auto"
      >
        {filteredProducts.length > 0 ? (
          <ul>
            {filteredProducts.map((product) => (
              <li
                key={product.id}
                className="py-2 px-4 border-b hover:bg-gray-100 cursor-pointer flex justify-between items-center"
                onClick={() => addToCart(product)}
              >
                <span>{product.nombre_producto}</span>
                <span className="flex flex-col items-end">
                  {product.tieneDescuento ? (
                    <>
                      <span className="text-gray-500 line-through text-sm">
                        ${product.precios[0].precio}
                      </span>
                      <span className="text-green-700 font-bold">
                        ${product.precioFinal}
                      </span>
                    </>
                  ) : (
                    <span className="text-green-700 font-bold">
                      ${getPrice(product)}
                    </span>
                  )}
                </span>
              </li>
            ))}
          </ul>
        ) : (
          <p className="p-4 text-center text-gray-500">No se encontraron productos.</p>
        )}

      </div>

      {
        cart.length > 0 && (
          <div className="text-center mb-6 mt-4 sticky bottom-24 z-10 bg-white p-4 rounded-lg shadow-md">
            <div className="mt-4">
              <div className="text-4xl font-semibold text-gray-800">{cart[cart.length - 1].nombre_producto}</div>
              <div className="text-5xl font-bold text-green-700">${getDiscountedPrice(cart[cart.length - 1])}</div>
            </div>
          </div>
        )
      }

      <h2 className="text-3xl font-bold text-center mb-6">Presupuesto</h2>

      {/* Carrito en formato de presupuesto */}

      {
        cart.length > 0 ? (
          <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead className="bg-gray-200">
              <tr>
                <th className="p-3 text-left font-semibold text-gray-700">Cantidad</th>
                <th className="p-3 text-left font-semibold text-gray-700">Producto</th>
                <th className="p-3 text-left font-semibold text-gray-700">Precio Unitario</th>
                <th className="p-3 text-left font-semibold text-gray-700">Subtotal</th>
                <th className="p-3  font-semibold text-center text-gray-700">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {cart.map((item) => (
                <tr key={item.id}>
                  <td className="p-3 text-gray-800">
                    <input
                      type="number"
                      value={item.quantity}
                      onChange={(e) => handleQuantityChange(item.id, e.target.value)}
                      min="1"
                      max={item.stock_unidades}
                      className={`w-16 p-1 border rounded text-center ${!item.quantity ? 'border-red-500' : 'border-gray-300'
                        }`} />
                  </td>
                  <td className="p-3 text-gray-800">{item.nombre_producto}</td>
                  <td className="p-3 text-gray-800">${getDiscountedPrice(item)}</td>
                  <td className="p-3 text-gray-800">${(getDiscountedPrice(item) * item.quantity).toFixed(2)}</td>
                  <td className="p-3 flex gap-2 justify-center">
                    <button
                      onClick={() => decreaseQuantity(item.id)}
                      className="px-4 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600"
                    >
                      -
                    </button>
                    <button
                      onClick={() => increaseQuantity(item.id)}
                      className="px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"
                    >
                      +
                    </button>
                    <button
                      onClick={() => removeFromCart(item.id)}
                      className="px-4 py-2 text-white bg-gray-500 rounded-lg hover:bg-gray-600"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>

          </table>
        ) : (
          <p className="text-center text-gray-700 mt-4">No hay productos en el carrito.</p>
        )
      }

      <div className="sticky bottom-16 z-20 bg-white p-4 rounded-lg shadow-md flex justify-between items-center text-6xl font-semibold text-gray-800">
        <span>Total:</span>
        {discount > 0 ? (
          <div className="flex flex-col items-end">
            <span className="text-4xl text-gray-500 line-through">${calculateTotal()}</span>
            <span className="text-red-500">${calculateTotalWithDiscount()}</span>
          </div>
        ) : (
          <span>${calculateTotal()}</span>
        )}
      </div>

      <div className="mt-6">
        <label className="block text-gray-700 font-bold mb-4">Método de pago</label>
        <div className="flex flex-row space-x-4">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={paymentMethod === 'efectivo'}
              onChange={() => handlePaymentMethodChange('efectivo')}
              className="w-5 h-5 text-blue-500"
            />
            <span>Efectivo</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={paymentMethod === 'transferencia'}
              onChange={() => handlePaymentMethodChange('transferencia')}
              className="w-5 h-5 text-blue-500"
            />
            <span>Transferencia</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={paymentMethod === 'debito'}
              onChange={() => handlePaymentMethodChange('debito')}
              className="w-5 h-5 text-blue-500"
            />
            <span>Débito</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={paymentMethod === 'credito'}
              onChange={() => handlePaymentMethodChange('credito')}
              className="w-5 h-5 text-blue-500"
            />
            <span>Crédito</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={paymentMethod === 'cuentaCorriente'}
              onChange={() => handlePaymentMethodChange('cuentaCorriente')}
              className="w-5 h-5 text-blue-500"
            />
            <span>Cuenta Corriente</span>
          </label>
        </div>
      </div>
      {/* Campo de descuento */}
      <div className="mt-4">
        <label className="block text-gray-700 font-bold mb-2">Descuento (%)</label>
        <input
          type="number"
          value={discount}
          onChange={handleDiscountChange}
          className="w-full p-2 border rounded-lg"
          placeholder="Ingrese porcentaje de descuento"
        />
      </div>


      <div className="text-center mt-6">
        <button
          onClick={handleCheckout}
          disabled={loading}
          className="w-full bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600"
        >
          {loading ? 'Cargando...' : 'Confirmar Venta'}
        </button>

      </div>
    </div >


  );
};

export default CompCarrito;
