import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../../context/AuthContext';
import { DatePicker, Button } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import locale from 'antd/es/date-picker/locale/es_ES';

const { RangePicker } = DatePicker;

dayjs.locale('es');

const HistorialCajas = () => {
  const { token } = useAuth();
  const [cajas, setCajas] = useState([]);
  const [cajaSeleccionada, setCajaSeleccionada] = useState(null);
  const [movimientos, setMovimientos] = useState([]);
  const [rangoFechas, setRangoFechas] = useState([]);
  const [saldoTotal, setSaldoTotal] = useState(0);
  const [saldoTotalEfectivo, setSaldoTotalEfectivo] = useState(0);

  useEffect(() => {
    const obtenerCajas = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/caja/allCajas`, {
          headers: { 'x-auth-token': token },
        });
        setCajas(response.data);
      } catch (error) {
        console.error('Error al obtener las cajas:', error);
      }
    };
    obtenerCajas();
  }, [token]);

  useEffect(() => {
    calcularSaldosTotales(cajas);
  }, [cajas]);

  const calcularSaldosTotales = (cajasAUsar) => {
    let totalSaldo = 0;
    let totalEfectivo = 0;
    cajasAUsar.forEach((caja) => {
      const saldoFinal = parseFloat(caja.saldo_final);
      const saldoEfectivo = parseFloat(caja.saldo_final_efectivo);
      if (!isNaN(saldoFinal)) totalSaldo += saldoFinal;
      if (!isNaN(saldoEfectivo)) totalEfectivo += saldoEfectivo;
    });
    setSaldoTotal(totalSaldo);
    setSaldoTotalEfectivo(totalEfectivo);
  };

  const verMovimientos = async (cajaId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/caja/${cajaId}`, {
        headers: { 'x-auth-token': token },
      });
      setCajaSeleccionada(response.data);
      setMovimientos(response.data.movimientos);
    } catch (error) {
      console.error('Error al obtener los movimientos de la caja:', error);
    }
  };

  const filtrarCajas = () => {
    return cajas.filter((caja) => {
      const fechaCaja = dayjs(caja.fecha_apertura);
      return (
        (!rangoFechas[0] || fechaCaja.isAfter(rangoFechas[0], 'day')) &&
        (!rangoFechas[1] || fechaCaja.isBefore(rangoFechas[1], 'day'))
      );
    });
  };

  const limpiarFiltros = () => {
    setRangoFechas([]);
  };

  const cajasFiltradas = filtrarCajas();

  useEffect(() => {
    calcularSaldosTotales(cajasFiltradas);
  }, [cajasFiltradas]);

  return (
    <div className='mt-6 container mx-auto px-4'>
      <h2 className="text-primary text-center mb-6 text-2xl font-bold">Historial de Cajas</h2>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 relative">
        <div className="col-span-2">
          <ul className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {cajasFiltradas.map((caja) => (
              <motion.li
                key={caja.id}
                className="p-4 bg-white shadow-md rounded-lg flex flex-col justify-between border-l-4"
                whileHover={{ scale: 1.03 }}
              >
                <div>
                  <p className="font-semibold text-lg text-gray-800">
                    {dayjs(caja.fecha_apertura).format('dddd, D [de] MMMM YYYY')}
                  </p>
                  <p className="text-gray-600">Saldo Inicial: ${caja.saldo_inicial}</p>
                  <p className="text-gray-600">Saldo Final: ${caja.saldo_final}</p>
                  <p className="text-gray-600">Saldo Efectivo: ${caja.saldo_final_efectivo}</p>
                </div>
                <button
                  onClick={() => verMovimientos(caja.id)}
                  className="mt-2 bg-blue-500 text-white px-3 py-1 rounded-lg hover:bg-blue-600"
                >
                  Ver Detalles
                </button>
              </motion.li>
            ))}
          </ul>
        </div>
        <div className="bg-white shadow-md rounded-lg p-4">
          <h2 className="text-xl font-bold mb-4">Filtrar por Fecha</h2>
          <RangePicker
            locale={locale}
            format="DD/MM/YYYY"
            value={rangoFechas}
            onChange={(dates) => setRangoFechas(dates || [])}
            className="w-full mb-4"
          />
          <Button onClick={limpiarFiltros} danger className="w-full">Limpiar Filtros</Button>
        </div>
      </div>
      <AnimatePresence>
        {cajaSeleccionada && (
          <motion.div
            className="fixed top-0 right-0 h-full w-full lg:w-1/3 bg-gray-100 p-6 rounded-lg shadow-lg overflow-y-auto"
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
          >
            <button className="text-red-500 hover:text-red-700 mb-4" onClick={() => setCajaSeleccionada(null)}>
              Cerrar Detalles
            </button>
            <h3 className="text-2xl font-semibold mb-4">Detalles de la Caja {cajaSeleccionada.id}</h3>
            <h4 className="text-lg font-medium mb-2">Movimientos:</h4>
            <ul>
              {movimientos.map((movimiento) => (
                <motion.li key={movimiento.id} className="bg-white p-4 rounded-lg shadow-sm border-b">
                  <p><span className="font-bold">{movimiento.tipo_movimiento}:</span> ${movimiento.monto}</p>
                  <p className="text-gray-600">{movimiento.concepto}</p>
                </motion.li>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default HistorialCajas;
