import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext'; // Importa el contexto de autenticación

const CierreCaja = ({ cajaId, saldoFinalCalculado, saldoFinalEfectivo, onCerrarCaja }) => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { token } = useAuth(); // Obtener el token desde el contexto

  const cerrarCaja = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/caja/cerrar/${cajaId}`, {
      }, {
        headers: {
          'x-auth-token': token, // Incluir el token en los headers
        },
      });

      setSuccess('Caja cerrada con éxito.');
      setError('');
      onCerrarCaja(); // Llama a la función para eliminar la ID de localStorage
    } catch (error) {
      setError('Error al cerrar la caja.');
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Cierre de Caja</h2>

      {error && <p className="text-red-500">{error}</p>}
      {success && <p className="text-green-500">{success}</p>}

      <p className="mb-4">Saldo Final: ${saldoFinalCalculado}</p> {/* Asegúrate de mostrarlo */}
      <p className="mb-4">Saldo Efectivo Final: ${saldoFinalEfectivo}</p> {/* Asegúrate de mostrarlo */}

      <button
        onClick={cerrarCaja}
        className="bg-red-500 text-white p-2 rounded-md w-full hover:bg-red-600"
      >
        Cerrar Caja
      </button>
    </div>
  );
};

export default CierreCaja;
