import React, { useState, useEffect, useRef } from 'react';
import CompEditProduct from './EditProducto';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useBeforeunload } from 'react-beforeunload';
import { FaTrashAlt, FaArrowUp } from 'react-icons/fa'; // Importa el ícono de la papelera
import { gsap } from 'gsap'; // Importa GSAP para animaciones
import { useScrollTrigger } from '@gsap/react'; // Usando useScrollTrigger
import { useGSAP } from '@gsap/react';

const CompInventario = () => {

  const [inventory, setInventory] = useState([]);
  const { token } = useAuth();
  const editProductRef = useRef({ hasUnsavedChanges: false });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [orderBy, setOrderBy] = useState("updatedAt");
  const [orderDirection, setOrderDirection] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const editContainerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [showScrollToTop, setShowScrollToTop] = useState(false);


  useEffect(() => {
    const fetchInventory = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/productos/admin`,
          {
            params: { orderBy, orderDirection, searchTerm },
            headers: { 'x-auth-token': token },
          }
        );
        setInventory(response.data.productos);
      } catch (error) {
        setError('Error al obtener los productos');
      } finally {
        setLoading(false);
      }
    };

    fetchInventory();
  }, [orderBy, orderDirection, searchTerm]);

  // 🚨 Bloquea la navegación al cerrar la pestaña o recargar la página
  useBeforeunload((event) => {
    if (editProductRef.current?.hasUnsavedChanges) {
      event.preventDefault();
    }
  });

  // 🚨 Bloquea el botón atrás del navegador
  useEffect(() => {
    const handlePopState = () => {
      if (editProductRef.current?.hasUnsavedChanges) {
        const confirmExit = window.confirm("Tienes cambios sin guardar. ¿Seguro que quieres salir?");
        if (!confirmExit) {
          navigate(location.pathname, { replace: true });
        }
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate, location]);

  const handleEditProduct = (productId) => {
    if (editProductRef.current?.hasUnsavedChanges) {
      const confirmExit = window.confirm("Tienes cambios sin guardar. ¿Seguro que quieres salir?");
      if (!confirmExit) return;
    }

    setSelectedProductId(productId);

    setTimeout(() => {
      editContainerRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }, 0);
  };

  const reloadInventory = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/productos/admin`,
        {
          params: { orderBy, orderDirection },
          headers: { 'x-auth-token': token },
        }
      );
      setInventory(response.data.productos);
    } catch (error) {
      setError('Error al obtener los productos');
    } finally {
      setLoading(false);
    }
  };

  const handleCloseEdit = () => {
    if (editProductRef.current?.hasUnsavedChanges) {
      const confirmExit = window.confirm("Tienes cambios sin guardar. ¿Seguro que quieres salir?");
      if (!confirmExit) return;
    }

    setSelectedProductId(null);
  };



  const handleDelete = async (productId) => {
    // Confirmación antes de eliminar
    const confirmed = window.confirm('¿Estás seguro de que deseas eliminar este producto?');

    if (confirmed) {
      try {
        // Llamada al backend para eliminar el producto
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/productos/admin/${productId}`,
          {
            headers: { 'x-auth-token': token },
          }
        );
        alert('Producto eliminado exitosamente');
        reloadInventory();
      } catch (error) {
        console.error('Error al eliminar el producto:', error);
        alert('Hubo un error al eliminar el producto');
      }
    }
  };



  // Detectamos el scroll utilizando el hook useScrollTrigger
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1800) { // Mostrar la flecha cuando el scroll haya pasado los 300px
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Usando el método nativo del navegador para el desplazamiento
  };

  useEffect(() => {
    if (showScrollToTop) {
      gsap.to('.scroll-to-top-btn', { opacity: 1, scale: 1, duration: 0.3 });
    } else {
      gsap.to('.scroll-to-top-btn', { opacity: 0, scale: 0.5, duration: 0.3});
    }
  }, [showScrollToTop]);

  return (
    <div className='text-center flex flex-col align-middle items-center p-3'>
      <div className="flex flex-col space-y-4 p-5 bg-gray-50 rounded-lg shadow-md border border-gray-200">
        <h1 className="text-primary">Gestión de Inventario</h1>
        <div className="grid grid-cols-1 py-5 md:grid-cols-3 gap-4">
          <div className="flex flex-col">
            <label htmlFor="searchTerm" className="mb-1 text-sm font-medium text-gray-600">
              Término de búsqueda:
            </label>
            <input
              autoFocus
              id="searchTerm"
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Ej: ref123 o nombre"
              className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="orderBy" className="mb-1 text-sm font-medium text-gray-600">
              Ordenar por:
            </label>
            <select
              id="orderBy"
              value={orderBy}
              onChange={(e) => setOrderBy(e.target.value)}
              className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
            >
              <option value="createdAt">Fecha de creación</option>
              <option value="updatedAt">Última actualización</option>
            </select>
          </div>

          <div className="flex flex-col">
            <label htmlFor="orderDirection" className="mb-1 text-sm font-medium text-gray-600">
              Dirección:
            </label>
            <select
              id="orderDirection"
              value={orderDirection}
              onChange={(e) => setOrderDirection(e.target.value)}
              className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
            >
              <option value="asc">Ascendente</option>
              <option value="desc">Descendente</option>
            </select>
          </div>
        </div>
      </div>

      <div className="flex items-start justify-center min-h-screen bg-gray-100 p-4">
        <div className="bg-white shadow-lg rounded-lg w-full max-w-6xl flex flex-col lg:flex-row">
          <div className={`w-full ${selectedProductId ? 'lg:w-2/5' : 'w-full'} p-8`}>
            <h2 className="text-xl font-semibold mb-4">Inventario Actual</h2>

            {loading && <p className="text-center text-gray-500">Cargando inventario...</p>}
            {error && <p className="text-center text-red-500">Error: {error}</p>}

            {!loading && !error && inventory.length > 0 ? (
              <ul className="space-y-4">
                {inventory.map((product) => (
                  <li
                    key={product.id}
                    className="border p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
                  >
                    <h3 className="text-lg font-bold">{product.nombre_producto}</h3>
                    <p className={`text-sm ${product.codigo_barras?.length ? 'text-gray-600' : 'text-red-500'}`}>
                      Plug asociado: {product.codigo_barras?.map(bar => bar.codigo).join(', ') || 'N/A'}
                    </p>
                    <p className="text-sm text-gray-600">Stock: {product.stock_unidades}</p>
                    <div className="flex justify-evenly mt-2">
                      <button
                        onClick={() => handleEditProduct(product.id)}
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      >
                        Editar
                      </button>
                      <button
                        onClick={() => handleDelete(product.id)}  // Llamada a la función de eliminación
                        className=" flex  bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                      >
                        <FaTrashAlt className="mr-2" /> Eliminar
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              !loading && <div className="flex  flex-col justify-center items-center">
                <p className='text-center text-gray-500'>No hay productos encontrados</p>
                <Link to={`/crear-producto?plug=${encodeURI(searchTerm)}`} className='text-blue-500 flex text-center gap-1 hover:underline'>Crear producto con plug:<p className='text-black'>{searchTerm}</p></Link>
                <Link
                  to={`/crear-producto?name=${encodeURIComponent(searchTerm)}`}
                  className="text-blue-500 flex text-center gap-1 hover:underline"
                >
                  Crear producto con Nombre: <span className="text-black">{searchTerm}</span>
                </Link>
              </div>
            )}
          </div>

          {selectedProductId && (
            <div
              ref={editContainerRef}
              className="bg-gray-50 border-l border-gray-300 px-4 h-full max-h-screen overflow-y-auto lg:w-3/5"
            >
              <CompEditProduct
                productId={selectedProductId}
                onSave={() => {
                  reloadInventory();
                  editProductRef.current.hasUnsavedChanges = false;
                }}
                handleCloseEdit={handleCloseEdit}
                ref={editProductRef}
              />
            </div>
          )}
        </div>
      </div>
      {showScrollToTop && (
        <button
          onClick={handleScrollToTop}
          className=" scroll-to-top-btn  fixed bottom-4 right-4 bg-blue-500 text-white p-2 rounded-full  hover:bg-blue-700"
        >
          <FaArrowUp  />
        </button>
      )}
    </div>
  );
};

export default CompInventario;
