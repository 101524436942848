import { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
const EditProv = ({ proveedor, onCancel, onUpdate }) => {
    const [nombre, setNombre] = useState("");
    const [direccion, setDireccion] = useState("");
    const [telefono, setTelefono] = useState("");
    const [email, setEmail] = useState("");
    const [contacto, setContacto] = useState("");
    const [abreviatura, setAbreviatura] = useState("");
    const [codigo, setCodigo] = useState("");

    useEffect(() => {
        if (proveedor) {
            setNombre(proveedor.nombre || "");
            setDireccion(proveedor.direccion || "");
            setTelefono(proveedor.telefono || "");
            setEmail(proveedor.email || "");
            setContacto(proveedor.contacto || "");
            setAbreviatura(proveedor.abreviatura || "");
            setCodigo(proveedor.codigo || "");
        }
    }, [proveedor]);

    const actualizarProveedor = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/proveedores/${proveedor.id}`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    proveedor: nombre,
                    direccion,
                    telefono,
                    email,
                    contacto,
                    abreviatura,
                    codigo
                })
            });
            if (!response.ok) throw new Error("Error al actualizar el proveedor");
            alert("Proveedor actualizado con éxito");
            if (onUpdate) onUpdate(); // 🔄 Actualizar lista de proveedores

            onCancel();

        } catch (error) {
            alert("Error: " + error.message);
            console.error("Error al actualizar proveedor:", error);
        }
    };

    return (
        <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">Editar Proveedor</h2>
                <button onClick={onCancel} className="text-gray-500 hover:text-gray-800">
                    <FaTimes size={18} />
                </button>
            </div>
            {/* Campos de edición */}
            <div className="space-y-4">
                <div>
                    <label className="block text-gray-700">Nombre</label>
                    <input type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} className="w-full border rounded-lg px-4 py-2" required />
                </div>
                <div>
                    <label className="block text-gray-700">Dirección</label>
                    <input type="text" value={direccion} onChange={(e) => setDireccion(e.target.value)} className="w-full border rounded-lg px-4 py-2" />
                </div>
                <div>
                    <label className="block text-gray-700">Teléfono</label>
                    <input type="text" value={telefono} onChange={(e) => setTelefono(e.target.value)} className="w-full border rounded-lg px-4 py-2" />
                </div>
                <div>
                    <label className="block text-gray-700">Email</label>
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="w-full border rounded-lg px-4 py-2" />
                </div>
                <div>
                    <label className="block text-gray-700">Contacto</label>
                    <input type="text" value={contacto} onChange={(e) => setContacto(e.target.value)} className="w-full border rounded-lg px-4 py-2" />
                </div>
                <div>
                    <label className="block text-gray-700">Abreviatura</label>
                    <input type="text" value={abreviatura} onChange={(e) => setAbreviatura(e.target.value)} className="w-full border rounded-lg px-4 py-2" required />
                </div>
                <div>
                    <label className="block text-gray-700">Código</label>
                    <input type="text" value={codigo} onChange={(e) => setCodigo(e.target.value)} className="w-full border rounded-lg px-4 py-2" required />
                </div>
                {/* Botones */}
                <div className="flex justify-end gap-2">
                    <button onClick={onCancel} className="bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded-lg">
                        Cancelar
                    </button>
                    <button type="button" onClick={actualizarProveedor} className="bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded-lg">
                        Actualizar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditProv;
