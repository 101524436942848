import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { DatePicker } from 'antd';
import 'antd/dist/reset.css';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween'; // Importa el plugin isBetween
dayjs.extend(isBetween); // Extiende dayjs con el plugin

const CompShowIngresos = () => {
    const [ingresos, setIngresos] = useState([]);
    const [filteredIngresos, setFilteredIngresos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedIngreso, setSelectedIngreso] = useState(null);
    const [dateFilter, setDateFilter] = useState(null);
    const { token } = useAuth();

    const getAllIngresos = async () => {
        try {
            const url = `${process.env.REACT_APP_API_URL}/ingresos/all`;
            const response = await axios.get(url, {
                headers: { 'x-auth-token': token },
            });
            setIngresos(response.data.ingresos);
            setFilteredIngresos(response.data.ingresos);
            console.log(response.data.ingresos);

        } catch (err) {
            setError('Hubo un error al obtener los ingresos. Intenta nuevamente.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllIngresos();
    }, []);

    useEffect(() => {
        if (!dateFilter) {
            setFilteredIngresos(ingresos);
            return;
        }

        const filtered = ingresos.filter(ingreso => {
            const ingresoDate = dayjs(ingreso.createdAt).startOf('day'); // Asegúrate que ingresoDate sea un objeto dayjs
            if (Array.isArray(dateFilter)) {
                const [start, end] = dateFilter;
                const startDate = dayjs(start).startOf('day');
                const endDate = dayjs(end).endOf('day');
                // Filtrado por rango de fechas
                return ingresoDate.isBetween(startDate, endDate, null, '[]');
            } else {
                // Filtrado por fecha exacta
                const selectedDate = dayjs(dateFilter).startOf('day');
                return ingresoDate.isSame(selectedDate, 'day');
            }
        });
        setFilteredIngresos(filtered);
    }, [dateFilter, ingresos]);

    const handleVerDetalles = (ingreso) => setSelectedIngreso(ingreso);

    if (loading) return <div className="text-center py-6 text-lg">Cargando ingresos...</div>;

    return (
        <div className="container mx-auto p-4 relative mt-2">
            <h1 className="text-primary text-center mb-6 text-2xl font-bold">Historial de ingresos</h1>

            {error && (
                <motion.div className="bg-red-500 text-white p-4 rounded-lg shadow-lg fixed top-4 right-4">
                    <p>{error}</p>
                </motion.div>
            )}

            <div className="flex flex-wrap justify-center gap-4 mb-6">
                <DatePicker
                    className="border p-2 rounded shadow-md"
                    placeholder="Seleccionar día"
                    onChange={(date, dateString) => setDateFilter(dateString || null)}
                />
                <DatePicker.RangePicker
                    className="border p-2 rounded shadow-md"
                    placeholder={['Fecha inicio', 'Fecha fin']}
                    onChange={(dates, dateStrings) => setDateFilter(dateStrings[0] && dateStrings[1] ? dateStrings : null)}
                />
            </div>

            <AnimatePresence>
                <motion.div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    {filteredIngresos.length > 0 ? (
                        filteredIngresos.map((ingreso) => (
                            <motion.div key={ingreso.id} className="bg-white p-6 border rounded-lg shadow-md hover:shadow-xl transition-shadow" whileHover={{ scale: 1.05 }} layout>
                                <p><strong>ID Ingreso:</strong> {ingreso.id}</p>
                                <p><strong>Fecha:</strong> {new Date(ingreso.createdAt).toLocaleDateString()}</p>
                                <p><strong>Cantidad de unidades:</strong> {ingreso.cantidad_unidades}</p>
                                <p><strong>Forma de pago:</strong> {ingreso.forma_pago}</p>
                                <button onClick={() => handleVerDetalles(ingreso)} className="mt-4 bg-blue-500 text-white px-4 py-2 rounded">Ver detalles</button>
                            </motion.div>
                        ))
                    ) : (
                        <p className="text-center text-gray-500">No se encontraron ingresos.</p>
                    )}
                </motion.div>
            </AnimatePresence>

            <AnimatePresence>
                {selectedIngreso && (
                    <motion.div key={selectedIngreso.id} initial={{ x: '100%' }} animate={{ x: 0 }} exit={{ x: '100%' }} transition={{ type: 'spring', stiffness: 80, damping: 20 }} className="fixed inset-y-0 right-0 w-full lg:w-1/3 bg-white shadow-lg z-50 p-6 overflow-y-auto">
                        <h2 className="text-2xl font-bold mb-4">Detalles del ingreso ID: {selectedIngreso.id}</h2>
                        <p><strong>Producto:</strong> {selectedIngreso.producto.nombre_producto || 'N/A'}</p>
                        <p><strong>Fecha:</strong> {new Date(selectedIngreso.createdAt).toLocaleDateString()}</p>
                        <p><strong>Cantidad de unidades:</strong> {selectedIngreso.cantidad_unidades
}</p>
                        <button onClick={() => setSelectedIngreso(null)} className="mt-4 bg-red-500 text-white px-4 py-2 rounded">Cerrar</button>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default CompShowIngresos;
