import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { useAuth } from '../context/AuthContext';
import { FaTrashAlt } from 'react-icons/fa';
const CompCrearDescuento = () => {
  const { token } = useAuth(); // Obtener el token del contexto
  const [productos, setProductos] = useState([]);
  const [filteredProductos, setFilteredProductos] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [descuentoPorcentaje, setDescuentoPorcentaje] = useState('');
  const [descuentoFijo, setDescuentoFijo] = useState('');
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFin, setFechaFin] = useState('');
  const [activo, setActivo] = useState(true);

  const getVencimientoColor = (estadoVencimiento) => {
    switch (estadoVencimiento) {
      case 'rojo':
        return 'bg-red-200'; // Rojo
      case 'amarillo':
        return 'bg-yellow-100'; // Amarillo
      case 'verde':
        return 'bg-green-200'; // Verde
      default:
        return 'bg-white'; // Sin fecha de vencimiento
    }
  };

  // Función para obtener los productos más actualizados
  const fetchProductos = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/productos/admin`, {
        headers: { 'x-auth-token': token },
      });
      const sortedProductos = response.data.productos.sort((a, b) => {
        if (!a.fecha_vencimiento) return 1;
        if (!b.fecha_vencimiento) return -1;
        return new Date(a.fecha_vencimiento) - new Date(b.fecha_vencimiento);
      });
      setProductos(sortedProductos);
      setFilteredProductos(sortedProductos);
    } catch (error) {
      console.error('Error al obtener productos:', error);
    }
  };

  useEffect(() => {
    fetchProductos();
  }, [token]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const filtered = productos.filter((producto) =>
      producto.nombre_producto.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredProductos(filtered);
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setDescuentoPorcentaje('');
    setDescuentoFijo('');
    setFechaInicio('');
    setFechaFin('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedProduct) {
      alert('Por favor, seleccione un producto');
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/descuentos`,
        {
          tipo: 'producto',
          id_relacionado: selectedProduct.id,
          descuento_porcentaje: descuentoPorcentaje || null,
          descuento_fijo: descuentoFijo || null,
          fecha_inicio: fechaInicio,
          fecha_fin: fechaFin,
          activo,
        },
        { headers: { 'x-auth-token': token } }
      );

      alert('Descuento creado exitosamente');
      setSelectedProduct(null);
      setSearchTerm('');
      fetchProductos(); // Recargar productos después de crear el descuento

    } catch (error) {
      console.error('Error al crear descuento:', error);
      alert('Error al crear el descuento');
    }
  };

  const handleDeleteDiscount = async (productId, event) => {
    event.stopPropagation(); // Detener la propagación del evento

    const confirmDelete = window.confirm('¿Estás seguro de que deseas eliminar este descuento?');
    if (!confirmDelete) return;

    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/descuentos/${productId}`, {
        headers: { 'x-auth-token': token },
      });
      alert('Oferta eliminada exitosamente');
      setProductos((prev) =>
        prev.map((prod) =>
          prod.id === productId ? { ...prod, tieneDescuento: false } : prod
        )
      );
      setSelectedProduct(null);
      setSearchTerm('');
      fetchProductos(); // Recargar productos después de eliminar el descuento

    } catch (error) {
      console.error('Error al eliminar Oferta:', error);
      alert('Error al eliminar la Oferta');
    }
  };

  return (
    <motion.div
      className="container mx-auto p-6 bg-white rounded-lg shadow-lg"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h2 className="mb-6 text-center text-primary text-2xl font-bold">Gestionar Ofertas</h2>

      {/* Barra de búsqueda */}
      <div className="mb-6">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Buscar producto..."
          className="w-full border border-gray-300 rounded-lg shadow-sm p-3 focus:ring-indigo-500 focus:border-indigo-500 transition duration-200 ease-in-out"
        />
      </div>

      {/* Listado de productos */}
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6 mb-6">
        {filteredProductos.map((product) => (
          <div
            key={product.id}
            onClick={() => handleProductSelect(product)}
            className={`p-4 rounded-lg shadow-md cursor-pointer hover:shadow-xl transition-all border ${getVencimientoColor(product.estadoVencimiento)} ${selectedProduct?.id === product.id ? 'ring-2 ring-indigo-500' : ''}`}
          >
            <h3 className="text-lg font-semibold text-gray-800">{product.nombre_producto}</h3>
            <p className="text-sm text-gray-500">
              {product.fecha_vencimiento
                ? new Date(product.fecha_vencimiento).toLocaleDateString()
                : 'Sin fecha de vencimiento'}
            </p>
            <div className="mt-2 space-y-1">
              <p className="text-sm text-gray-700">Precio de venta: ${product.precios?.[0]?.precio || 'N/A'}</p>
              <p className="text-sm text-gray-700">Precio sin IVA: ${product.precios?.[0]?.precio_compra || 'N/A'}</p>
              <p className="text-sm text-gray-700">Mayorista A: ${product.precios?.[0]?.precio_mayorista_a || 'N/A'}</p>
              <p className="text-sm text-gray-700">Mayorista B: ${product.precios?.[0]?.precio_mayorista_b || 'N/A'}</p>
              <p className="text-sm text-gray-700">Mayorista C: ${product.precios?.[0]?.precio_mayorista_c || 'N/A'}</p>
            </div>
            {product.tieneDescuento && (
              <div className="mt-2 flex justify-between items-center bg-red-100 p-2 rounded-md">
                <p className="text-red-600 font-bold">Oferta activa</p>
                <p className="text-red-600">Precio final: ${product.precioFinal || 'N/A'}</p>
                <button
                  onClick={(event) => handleDeleteDiscount(product.id, event)}
                  className="text-red-500 hover:text-red-700 transition duration-150"
                >
                  <FaTrashAlt />
                </button>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Formulario de creación de descuento */}
      {selectedProduct && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <motion.div
            className="bg-white rounded-lg p-6 shadow-lg max-w-md w-full"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
          >
            <h3 className="text-xl font-bold text-center text-gray-800">Producto: {selectedProduct.nombre_producto}</h3>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="descuentoPorcentaje" className="block text-sm font-medium text-gray-700">
                  Descuento Porcentaje:
                </label>
                <input
                  id="descuentoPorcentaje"
                  type="number"
                  value={descuentoPorcentaje}
                  onChange={(e) => setDescuentoPorcentaje(e.target.value)}
                  className="mt-2 block w-full border border-gray-300 rounded-lg shadow-sm p-3 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div>
                <label htmlFor="descuentoFijo" className="block text-sm font-medium text-gray-700">
                  Descuento Fijo:
                </label>
                <input
                  id="descuentoFijo"
                  type="number"
                  value={descuentoFijo}
                  onChange={(e) => setDescuentoFijo(e.target.value)}
                  className="mt-2 block w-full border border-gray-300 rounded-lg shadow-sm p-3 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div>
                <label htmlFor="fechaInicio" className="block text-sm font-medium text-gray-700">
                  Fecha Inicio:
                </label>
                <input
                  id="fechaInicio"
                  type="date"
                  value={fechaInicio}
                  onChange={(e) => setFechaInicio(e.target.value)}
                  className="mt-2 block w-full border border-gray-300 rounded-lg shadow-sm p-3 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div>
                <label htmlFor="fechaFin" className="block text-sm font-medium text-gray-700">
                  Fecha Fin:
                </label>
                <input
                  id="fechaFin"
                  type="date"
                  value={fechaFin}
                  onChange={(e) => setFechaFin(e.target.value)}
                  className="mt-2 block w-full border border-gray-300 rounded-lg shadow-sm p-3 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div className="flex items-center">
                <input
                  id="activo"
                  type="checkbox"
                  checked={activo}
                  onChange={(e) => setActivo(e.target.checked)}
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label htmlFor="activo" className="ml-2 text-sm font-medium text-gray-700">Activo</label>
              </div>
              <div className="flex justify-between mt-6">
                <button
                  type="button"
                  onClick={() => setSelectedProduct(null)}
                  className="py-2 px-4 bg-gray-300 rounded-lg shadow-md hover:bg-gray-400 transition"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="py-2 px-4 bg-indigo-600 text-white rounded-lg shadow-md hover:bg-indigo-700 transition"
                >
                  Crear Oferta
                </button>
              </div>
            </form>
          </motion.div>
        </div>
      )}

    </motion.div>

  );
};

export default CompCrearDescuento;
