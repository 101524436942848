import React, { useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { useAuth } from '../context/AuthContext';  

function CompCreateProv({ onClose, onCreateProveedor }) {
  const { token } = useAuth();  // Obtener el token del contexto
  const [provData, setProvData] = useState({
    proveedor: '',
    direccion: '',
    telefono: '',
    email: '',
    contacto: '',  // Campo de contacto agregado
    abreviatura: '',
    codigo: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProvData({
      ...provData,
      [name]: value,
    });
  };

  const handleButtonClick = async () => {
    try {

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/proveedores`,
        provData,
        {
          headers: {
            'x-auth-token': token,  // Agregar el token en los headers
          },
        }
      );
      onCreateProveedor();
      onClose(); // Cierra el modal después de crear el proveedor
    } catch (error) {
      console.error('Error al crear el proveedor:', error);
    }
  };

  const isFormValid = provData.proveedor;

  return (
    <motion.div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
        <h2 className="text-2xl font-semibold mb-6">Crear Proveedor</h2>
        <div>
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">Nombre del proveedor:</label>
            <input
              type="text"
              name="proveedor"
              value={provData.proveedor}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">Abreviatura:</label>
            <input
              type="text"
              name="abreviatura"
              value={provData.abreviatura}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">Dirección:</label>
            <input
              type="text"
              name="direccion"
              value={provData.direccion}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">Teléfono:</label>
            <input
              type="number"
              name="telefono"
              value={provData.telefono}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">Correo electrónico:</label>
            <input
              type="email"
              name="email"
              value={provData.email}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">Persona de contacto:</label>
            <input
              type="text"
              name="contacto"
              value={provData.contacto}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">Código de proveedor:</label>
            <input
              type="text"
              name="codigo"
              value={provData.codigo}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
            />
          </div>

          {/* Botones de acción */}
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-4 bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400"
            >
              Cancelar
            </button>
            <button
              type="button"
              onClick={handleButtonClick}
              disabled={!isFormValid} // Deshabilitar si el formulario no está completo
              className={`py-2 px-4 rounded-lg text-white ${isFormValid ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-400 cursor-not-allowed'}`}
            >
              Crear Proveedor
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default CompCreateProv;
