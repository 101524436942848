import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

const CompShowVentas = () => {
  const [ventas, setVentas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVenta, setSelectedVenta] = useState(null); // Venta seleccionada
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFin, setFechaFin] = useState('');
  const { token } = useAuth(); // Obtener el token del contexto

  const fetchVentas = async (inicio = '', fin = '') => {
    try {
      let url = `${process.env.REACT_APP_API_URL}/ventas/admin`;

      if (inicio && fin) {
        url += `?fechaInicio=${inicio}&fechaFin=${fin}`;
      }

      const response = await axios.get(url, {
        headers: {
          'x-auth-token': token, // Agregar el token en los headers
        },
      });
      setVentas(response.data);

    } catch (err) {
      setError('Hubo un error al obtener las ventas. Intenta nuevamente.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVentas();
  }, []);

  const handleBuscarPorFechas = () => {
    if (fechaInicio && fechaFin) {
      fetchVentas(fechaInicio, fechaFin);
    } else {
      setError('Por favor, selecciona ambas fechas.');
    }
  };

  const handleVerDetalles = (venta) => {
    setSelectedVenta(venta);
  };

  const closeError = () => {
    setError(null);
  };

  // Cerrar la notificación de error después de 3 segundos
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 2000); // 3000 ms = 3 segundos

      return () => clearTimeout(timer); // Limpiar el timeout si el componente se desmonta
    }
  }, [error]);

  if (loading) {
    return <div className="text-center py-6">Cargando ventas...</div>;
  }

  return (

    <div className="container mx-auto p-4 relative mt-2">
      <h1 className="text-primary text-center mb-12">Historial de ventas</h1>

      {/* Filtros de búsqueda por fechas */}
      <div className="mb-6 bg-gray-100 p-4 rounded-md shadow-sm">
        <h2 className="text-lg font-semibold mb-4">Filtrar por fechas</h2>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <div>
            <label className="block text-gray-700 mb-2">Fecha de inicio:</label>
            <input
              type="date"
              value={fechaInicio}
              onChange={(e) => setFechaInicio(e.target.value)}
              className="w-full border p-2 rounded"
            />
          </div>

          <div>
            <label className="block text-gray-700 mb-2">Fecha de fin:</label>
            <input
              type="date"
              value={fechaFin}
              onChange={(e) => setFechaFin(e.target.value)}
              className="w-full border p-2 rounded"
            />
          </div>

          <div className="flex items-end">
            <button
              onClick={handleBuscarPorFechas}
              className="bg-blue-500 text-white px-4 py-2 rounded w-full"
            >
              Buscar
            </button>
          </div>
        </div>
      </div>

      {/* Notificación de error */}
      <AnimatePresence>
        {error && (
          <motion.div
            className="fixed top-4 right-4 bg-red-500 text-white p-4 rounded-lg shadow-lg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="flex justify-between items-center">
              <p>{error}</p>
              <button onClick={closeError} className="ml-4 text-xl">X</button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Mostramos las ventas */}
      <AnimatePresence>
        <motion.div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {ventas.length > 0 ? (
            ventas.map((venta) => (
              <motion.div
                key={venta.id}
                className="bg-white p-6 border rounded-lg shadow-md"
                whileHover={{ scale: 1.05 }}
                layout
              >
                <p><strong>ID Venta:</strong> {venta.id}</p>
                <p><strong>Cliente:</strong> {venta.cliente}</p>
                <p><strong>Fecha:</strong> {new Date(venta.createdAt).toLocaleDateString()}</p>
                <p><strong>Total:</strong> ${venta.total}</p>
                <p>
                  {venta.descuento > 0 ? (
                    <span style={{ color: 'green', fontWeight: 'bold' }}>
                      Descuento del {venta.descuento}%
                    </span>
                  ) : (
                    <span style={{ color: 'gray' }}>
                      <strong>Sin descuento.</strong>
                    </span>
                  )}
                </p>

                <button
                  onClick={() => handleVerDetalles(venta)}
                  className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Ver detalles
                </button>
              </motion.div>
            ))
          ) : (
            <p>No se encontraron ventas.</p>
          )}
        </motion.div>
      </AnimatePresence>

      {/* Modal para detalles de una venta específica */}
      <AnimatePresence>
        {selectedVenta && (
          <motion.div
            key={selectedVenta.id}
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'spring', stiffness: 80, damping: 20 }}
            className="fixed inset-y-0 right-0 w-full lg:w-1/3 bg-white shadow-lg z-50 p-6 overflow-y-auto"
          >
            <h2 className="text-2xl font-bold mb-4">Detalles de la venta ID: {selectedVenta.id}</h2>
            <p><strong>Cliente:</strong> {selectedVenta.cliente}</p>
            <p><strong>Fecha:</strong> {new Date(selectedVenta.createdAt).toLocaleDateString()}</p>
            <p><strong>Total:</strong> ${selectedVenta.total}</p>
            <p>
              {selectedVenta.descuento > 0 ? (
                <span style={{ color: 'green', fontWeight: 'bold' }}>
                  Descuento del {selectedVenta.descuento}%
                </span>
              ) : (
                <span style={{ color: 'gray' }}>
                  <strong>Sin descuento.</strong>
                </span>
              )}
            </p>
            <h3 className="text-lg font-semibold mt-4">Detalles del pedido:</h3>
            <ul className="list-disc pl-5 mt-2">
              {selectedVenta.detalles_venta.map((detalle) => (
                <li key={detalle.id}>
                  Producto: {detalle.producto.nombre_producto} | Cantidad: {detalle.cantidad} | Precio: ${detalle.precio}
                </li>
              ))}
            </ul>

            <button
              onClick={() => setSelectedVenta(null)}
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
            >
              Cerrar
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CompShowVentas;
